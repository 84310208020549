// Removes space under images
.p-image-preview-container {
  display: flex !important;
}

// Fix dragged elements in orderlist not visible
.p-orderlist-item:not(.cdk-drag-disabled) {
  cursor: move;
  z-index: 1130 !important;
}

// Fix weird grey blob left of dividers
.p-divider-dotted.p-divider-horizontal:before {
  border-left-style: none;
}

// Fix overly wide dialog content
.p-dialog-content {
  max-width: 100vw;
}
