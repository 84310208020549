$mobile-breakpoint: 1000px;
$prime-xl: 1200px;

@mixin on-mobile {
  @media (screen and max-width: $mobile-breakpoint) {
    @content;
  }
}

@mixin prime-xl {
  @media (screen and max-width: $prime-xl) {
    @content;
  }
}
