.form-label {
  font-weight: bolder;
  margin-top: 1rem;
}

.form-actions {
  margin-top: 1rem;
}

.lc-control-group.disabled {
  opacity: 0.5;
}
