@use "app/styles/media-queries" as m;
@use "app/styles/auth-views";
@use "app/styles/forms";
@use "app/styles/grades";
@use "app/styles/data-view";
@use "app/styles/typography";
@use "app/styles/primeng-fixes";
@use "app/styles/primeng-mobile-optimisation";
@use "app/styles/main-views";
@use "app/styles/util";
@use "app/styles/confirm-dialog";
@import "primeicons/primeicons.css";

body {
  padding: 0;
  margin: 0;
}

html {
  height: 100vh;
  --ticked-bg-color: #eafeee;
  --ticked-color: var(--green-600);
}

body {
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.main {
  flex: auto;
  align-items: stretch;
  display: flex;

  > *:not(router-outlet) {
    width: 100%;
  }
}

.content {
  padding: 3rem 6rem;
  height: 100%;
  width: 100%;

  @include m.on-mobile {
    padding: 0 0;
  }

  .p-card-content {
    padding-bottom: 0;
  }
}

.p-confirm-popup {
  max-width: 500px;
}

p-avatar img {
  object-fit: cover;
}
